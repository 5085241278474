import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import './App.scss'
import GameHomePage from "./pages/GameHome";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import Loading from "./components/Loading";
import {useAppDispatch, useAppSelector} from "./store";
import {checkAuthAction} from "./store/reducers/auth";
import LineupIndexPage from "./pages/Lineup";
import CreateLineupPage from "./pages/LineupCreate";
import LookupPlayersPage from "./pages/LookupPlayers";
import LookupPlayerDetailsPage from "./pages/LookupPlayerDetails";

const Routes = () => {

  const auth = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthAction());
  }, [dispatch]);

  if (!auth.checked) {
    return <Loading/>;
  }

  if (!auth.isAuth) {
    return <LoginPage/>;
  }

  return (
    <Switch>
      <Route path="/lineups/new">
        <CreateLineupPage />
      </Route>
      <Route path="/lineups/:id">
        <LineupIndexPage/>
      </Route>
      <Route path="/play">
        <GameHomePage/>
      </Route>
      <Route exact path="/lookup/players/:id">
        <LookupPlayerDetailsPage/>
      </Route>
      <Route exact path="/lookup/players">
        <LookupPlayersPage/>
      </Route>
      <Route path="/">
        <HomePage/>
      </Route>
    </Switch>
  );
};

export default Routes;
