import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import FloatingButton from "../../components/FloatingButton";
import URLs from "../../config/urls";
import {LineupPlayer, PlayerPosition} from "../../types";
import Popup from "../../components/Popup";
import {activateLineupAction, activateLineupReset} from "../../store/reducers/lineup";

interface ActivateError {
  message: string
  label: string
  url: string
}

const LineupSummaryPage = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<ActivateError|null>(null);
  const lineup = useAppSelector(state => state.lineup.lineup)!;
  const activateLineupState = useAppSelector(state => state.lineup.activateLineupState)!;
  const dispatch = useAppDispatch();

  const withPosition = (position: PlayerPosition) => lineup.players.filter(p => p.position === position);

  const handleActivate = () => {
    const primaryTeamUrl = URLs.lineup(lineup.id).overview.replace(":group", "PRIMARY")
    const substituteTeamUrl = URLs.lineup(lineup.id).overview.replace(":group", "SUBSTITUTE")

    const coach = withPosition("COACH");
    if (coach.length === 0 || !coach[0].player) {
      setError({message: t('activate_error_no_coach'), label: t('select'), url: URLs.lineup(lineup.id).coach});
    } else if (!lineup.formation) {
      setError({message: t('activate_error_missing_formation'), label: t('select'), url: URLs.lineup(lineup.id).type});
    } else if (lineup.players.filter(p => p.group === "PRIMARY" && p.player).length < 11) {
      setError({message: t('activate_error_missing_players'), label: t('select'), url: primaryTeamUrl});
    } else if (lineup.players.filter(p => p.group === "SUBSTITUTE" && p.player).length < 7) {
      setError({
        message: t('activate_error_missing_subs'),
        label: t('select'),
        url: substituteTeamUrl
      });
    } else if (lineup.remainingBudget < 0) {
      setError({
        message: t('activate_error_over_budget'),
        label: t('select'),
        url: primaryTeamUrl
      })
    } else {
      dispatch(activateLineupAction(lineup.id))
    }
  }

  useEffect(() => {
    if (activateLineupState === "succeeded") {
      dispatch(activateLineupReset());
      history.push(URLs.home);
    }
  }, [activateLineupState])

  const handleKeepPassive = () => {
    history.push(URLs.home);
  }

  const coach = () => {
    const found = withPosition("COACH");
    if (found.length > 0 && found[0].player) return found[0].player.name;
    return '-';
  }

  const renderPlayerRow = (group: LineupPlayer[], p: LineupPlayer, i: number) => {
    const classes = ['summary-row'];
    if (i === (group.length - 1)) {
      classes.push('summary-row--last')
    }
    return <div key={i} className={classes.join(' ')}>
      <div className={`summary-row-position summary-row-position--${p.position}`}>{p.position[0]}</div>
      {p.player ? p.player.name : '-'}
    </div>
  }

  const primaryPlayers = lineup.players.filter(p => p.group === "PRIMARY" && p.position !== "COACH");
  const substitutePlayers = lineup.players.filter(p => p.group === "SUBSTITUTE");

  return <div className="summary">
    <div className="summary-header">{t('coach')}</div>
    <div className="summary-row summary-row--last">{coach()}</div>
    <div className="summary-header">{t('formation')}</div>
    <div className="summary-row summary-row--last">{lineup.formation || '-'}</div>
    <div className="summary-header">{t('primary_lineup')}</div>
    {
      primaryPlayers.map((p, i) => renderPlayerRow(primaryPlayers, p, i))
    }
    <div className="summary-header">{t('substitutes')}</div>
    {
      substitutePlayers.map((p, i) => renderPlayerRow(substitutePlayers, p, i))
    }
    <FloatingButton action={handleKeepPassive} direction="left" label={t('keep_as_draft')} />
    <FloatingButton action={handleActivate} direction="right" label={t('activate')} />
    {
      error &&
        <Popup message={error.message} action={{label: error.label, url: error.url}} onClose={() => setError(null)}/>
    }
  </div>;
}

export default LineupSummaryPage;
