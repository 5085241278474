import { combineReducers } from "redux";
import auth from "./auth";
import lineup from "./lineup";
import player from "./player";
import error from "./error";

export default combineReducers({
  auth: auth.reducer,
  lineup: lineup.reducer,
  player: player.reducer,
  error: error.reducer,
});
