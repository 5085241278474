import React from "react";
import './style.scss';

interface Props {
  action: () => void;
  label: string
  direction: string
  disabled?: boolean
}

const FloatingButton = ({action, label, direction, disabled}: Props) => {
  const getLabel = () => {
    if (direction === "right") return `${label} »`;
    if (direction === "left") return `« ${label}`;
    return label;
  }
  const classes = [`floating-btn`, `floating-btn--${direction}`];
  if (disabled) {
    classes.push('floating-btn-disabled');
  }
  return <div className={`floating-btn floating-btn--${direction}`} onClick={action}>{getLabel()}</div>
}

FloatingButton.defaultProps = {
  direction: "right"
};

export default FloatingButton;