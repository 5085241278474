import HTTPService from "./HTTPService";
import SessionService from "./SessionService";
import {PlayerSearchQueryParams} from "types";

function paramsToQuery<T>(params: T): string {
  let qsParams: string[] = [];
  let k: keyof T;
  for (k in params) {
    if (params[k] !== undefined && params[k] !== null) {
      qsParams.push(`${k}=${params[k]}`);
    }
  }

  return qsParams.length > 0 ? `?${qsParams.join('&')}` : '';
}

const login = (username: string) => {
  return HTTPService
    .post('/auth/login', {voleUsername: username})
    .then(res => {
      SessionService.setToken(res.token);
      return res;
    }).catch(err => {
      SessionService.removeToken();
      throw err;
    })
};

const checkAuth = () => HTTPService.get('/auth');

const createLineup = (periodId: number) => HTTPService.post('/lineups', {periodId});

const getLineup = (id: string) => HTTPService.get(`/lineups/${id}`);

const getLineupFormations = () => HTTPService.get(`/lineups/formations`);

const setLineupFormation = (lineupId: number, formationId: number) => HTTPService.post(`/lineups/${lineupId}`, {formationId});

const searchPlayers = (criteria: PlayerSearchQueryParams) => HTTPService.get(`/players${paramsToQuery(criteria)}`);

const getPlayerDetails = (id: number) => HTTPService.get(`/players/${id}`);

const getPlayerWeeklyScores = (id: number) => HTTPService.get(`/players/${id}/scores/weekly`);

const setLineupPlayer = (lineupId: number, playerId: number, group: string, position: string, rank: number) => {
  return HTTPService.post(`/lineups/${lineupId}/players`, {playerId, group, position, rank});
}

const activateLineup = (id: number) => HTTPService.post(`/lineups/${id}/activate`);
const deactivateLineup = (id: number) => HTTPService.post(`/lineups/${id}/deactivate`);

const getPeriod = (id?: number) => {
  const url = id ? `periods/${id}` : `/periods/active`;
  return HTTPService.get(url);
}

export default {
  login,
  checkAuth,
  getPeriod,
  createLineup,
  getLineup,
  searchPlayers,
  setLineupPlayer,
  getLineupFormations,
  setLineupFormation,
  activateLineup,
  getPlayerDetails,
  deactivateLineup,
  getPlayerWeeklyScores,
}
