import React from "react";
import "./style.css";

interface Props {
  small?: boolean;
  white?: boolean;
}

const Spinner = ({ white = false, small = false }: Props) => {
  const style = {
    stroke: white ? "hsl(210, 70%, 75%)" : "#ec2227",
  };

  const cs = ["spinner"];
  if (small) {
    cs.push("spinner-small");
  }

  return (
    <svg className={cs.join(" ")} viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
        style={style}
      />
    </svg>
  );
};

export default Spinner;
