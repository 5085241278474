import React from "react";
import Spinner from "./Spinner";

interface LoadingProps {
    message?: string
}

const Loading = ({message}: LoadingProps) => {

    return <div style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Spinner/>
        {
            message && <div style={{margin: 30, textAlign: 'center'}}>{message}</div>
        }
    </div>
}

export default Loading;