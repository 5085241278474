const URLs = {
  home: "/",
  gameHome: "/play",
  lookupPlayers: "/lookup/players",
  lookupPlayerDetails: "/lookup/players/:id",
  createLineup: "/lineups/new",
  lineup: (id: number) => ({
    home: `/lineups/${id}`,
    coach: `/lineups/${id}/coach`,
    type: `/lineups/${id}/type`,
    overview: `/lineups/${id}/overview/:group`,
    summary: `/lineups/${id}/summary`,
    selectPlayer: `/lineups/${id}/players/:group/:position/:rank`,
  })
}

export default URLs;