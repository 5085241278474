import session from 'services/SessionService';

console.log(process.env);
const apiHost = process.env.NODE_ENV === 'production' || process.env.REACT_APP_USE_PROD_API === 'true' ?
  'https://api.fs.vole.io' :
  'http://localhost:9000';

const getHeaders = () => {
  const headers = new Headers()
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const token = session.getToken();
  if (token !== undefined) {
    headers.append('X-Auth-Token', token);
  }

  return headers;
};

const processRawResponse = (rawResponse: Response) => {
  if (rawResponse.status === 401) {
    throw new Error('AUTH_REQUIRED')
  }
  if (rawResponse.status === 403) {
    throw new Error('AUTH_REQUIRED')
  }
  return rawResponse
    .text()
    .then(data => {

      if (rawResponse.status > 300) {
        throw new Error(data)
      }

      try {
        if (!data || data.length === 0) {
          return null;
        }
        return JSON.parse(data);
      } catch (e) {
        console.error("Cannot parse API response: ", e)
        return null;
      }
    });
};

const apiGet = (url: string) => fetch(apiHost + url, {
  method: 'GET',
  headers: getHeaders()
}).then(processRawResponse);

const apiDelete = (url: string) => fetch(apiHost + url, {
  method: 'DELETE',
  headers: getHeaders()
}).then(processRawResponse);

const apiPost = (url: string, data?: {}) => fetch(apiHost + url, {
  method: 'POST',
  headers: getHeaders(),
  body: data === undefined ? null : JSON.stringify(data)
}).then(processRawResponse);

export default {
  get: apiGet,
  post: apiPost,
  delete: apiDelete,
}
