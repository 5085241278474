import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Layout from "../components/Layout";
import InputBox from "../components/InputBox";
import {PlayerSearchQueryParams} from "../types";
import useDebounce from "../hooks/useDebounce";
import {searchPlayersAction} from "../store/reducers/player";
import {useAppDispatch, useAppSelector} from "../store";
import PlayerBox from "../components/PlayerBox";
import LoadMore from "../components/LoadMore";
import URLs from "../config/urls";

const PLAYERS_PER_PAGE = 10;

const LookupPlayers = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const players = useAppSelector(state => state.player.players)!;
  const hasMore = useAppSelector(state => state.player.hasMore)!;
  const getPlayersState = useAppSelector(state => state.player.getPlayersState);
  const [query, setQuery] = useState<PlayerSearchQueryParams>({
    limit: PLAYERS_PER_PAGE,
    append: false,
    skip: 0
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const search = (name?: string) => {
    if (name !== undefined) {
      setQuery({...query, append: false, name});
    }
  }
  useDebounce(searchTerm, search);
  useEffect(() => {
    if (query.name) {
      dispatch(searchPlayersAction(query));
    }
  }, [query])

  const handleLoadMore = () => setQuery({...query, append: true});

  const handleSelect = (id: number) => history.push(URLs.lookupPlayerDetails.replace(":id", `${id}`))

  return <Layout>
    <div className="lookup-players-header">{t('lookup_players')}</div>
    <InputBox placeholder={t('search_player_placeholder')} onChange={setSearchTerm}/>
    {
      players &&
      <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap"}}>
        {
          players.map(p => {
            return <PlayerBox key={p.id} player={p} onClick={handleSelect} />
          })
        }
      </div>
    }
    <LoadMore enabled={players.length > 0 && getPlayersState === "succeeded" && hasMore} onLoadMore={handleLoadMore}/>
  </Layout>
}

export default LookupPlayers;