import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, createSelectorHook } from "react-redux";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = createSelectorHook<RootState>();
