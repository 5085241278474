import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import API from "../../services/APIService";
import {Lineup, Period, ProgressState} from "types";
import APIService from "../../services/APIService";

export interface LineupState {
  period?: Period
  lineup?: Lineup
  activateLineupState: ProgressState
  getPeriodState: ProgressState
  getLineupState: ProgressState
  createLineupState: ProgressState
  setPlayerState: ProgressState
}

const initialState: LineupState = {
  getPeriodState: "idle",
  getLineupState: "idle",
  createLineupState: "idle",
  setPlayerState: "idle",
  activateLineupState: "idle",
};

const reducer = createSlice({
  name: "lineup",
  initialState,
  reducers: {
    getLineup(state) {
      state.getLineupState = "loading";
    },
    getLineupSuccess(state, action: PayloadAction<Lineup>) {
      state.getLineupState = "succeeded";
      state.lineup = action.payload;
    },
    getLineupError(state) {
      state.getLineupState = "failed";
    },
    getPeriod(state) {
      state.getPeriodState = "loading";
    },
    getPeriodSuccess(state, action: PayloadAction<Period>) {
      state.getPeriodState = "succeeded";
      state.period = action.payload;
    },
    getPeriodError(state) {
      state.getPeriodState = "failed";
    },
    createLineup(state) {
      state.createLineupState = "loading";
    },
    createLineupSuccess(state, action: PayloadAction<Lineup>) {
      state.createLineupState = "succeeded";
      state.lineup = action.payload;
      state.period!.lineup = {
        id: action.payload.id,
        status: action.payload.status,
      };
    },
    createLineupError(state) {
      state.createLineupState = "failed";
    },
    setPlayerReset(state) {
      state.setPlayerState = "idle";
    },
    setPlayerInit(state) {
      state.setPlayerState = "loading";
    },
    setPlayerError(state, action: PayloadAction<string>) {
      state.setPlayerState = "failed";
    },
    setPlayerSuccess(state) {
      state.setPlayerState = "succeeded";
    },
    activateLineupReset(state) {
      state.activateLineupState = "idle";
    },
    activateLineupInit(state) {
      state.activateLineupState = "loading";
    },
    activateLineupError(state, action: PayloadAction<string>) {
      state.activateLineupState = "failed";
    },
    activateLineupSuccess(state) {
      state.activateLineupState = "succeeded";
    },
  },
});

const {
  getPeriod,
  getPeriodError,
  getPeriodSuccess,
  createLineup,
  createLineupError,
  createLineupSuccess,
  getLineup,
  getLineupError,
  getLineupSuccess,
  setPlayerInit,
  setPlayerError,
  setPlayerSuccess,
  activateLineupInit,
  activateLineupError,
  activateLineupSuccess,
} = reducer.actions;

export const {activateLineupReset, setPlayerReset} = reducer.actions;

export const getPeriodAction = (id?: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(getPeriod());
  API.getPeriod(id)
    .then(res => dispatch(getPeriodSuccess(res)))
    .catch(e => {
      console.log(`Failed to get period: ${e.message}`);
      dispatch(getPeriodError());
    })
};

export const createLineupAction = (periodId: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(createLineup());
  API.createLineup(periodId)
    .then(res => dispatch(createLineupSuccess(res)))
    .catch(e => {
      console.log(`Failed to get period: ${e.message}`);
      dispatch(createLineupError());
    })
};

export const getLineupAction = (id: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(getLineup());
  API.getLineup(`${id}`)
    .then(res => dispatch(getLineupSuccess(res)))
    .catch(e => {
      console.log(`Failed to get lineup: ${e.message}`);
      dispatch(getLineupError());
    })
};

export const setPlayerAction = (lineupId: number, playerId: number, group: string, position: string, rank: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(setPlayerInit());
  API.setLineupPlayer(lineupId, playerId, group, position, rank)
    .then(() => {
      dispatch(setPlayerSuccess())
      dispatch(getLineupAction(lineupId))
      dispatch(setPlayerReset())
    })
    .catch(err => dispatch(setPlayerError(err.message)))
}

export const activateLineupAction = (lineupId: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(activateLineupInit());
  APIService.activateLineup(lineupId)
    .then(() => {
      dispatch(activateLineupSuccess())
    })
    .catch(err => dispatch(activateLineupError(err.message)))

}

export default reducer;
