import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import URLs from "../config/urls";
import Layout from "../components/Layout";
import BorderedButton from "../components/BorderedButton";
import {getPeriodAction} from "../store/reducers/lineup";
import {useAppDispatch, useAppSelector} from "../store";
import Loading from "../components/Loading";

const Home = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const getPeriodState = useAppSelector(state => state.lineup.getPeriodState);
  const period = useAppSelector(state => state.lineup.period);

  useEffect(() => {
    dispatch(getPeriodAction());
  }, [dispatch]);

  if (getPeriodState === "loading") return <Loading/>;

  const handleBuildLineup = () => {
    const url = period && period.lineup ? URLs.lineup(period.lineup.id).overview.replace(":group", "PRIMARY") : URLs.createLineup;
    history.push(url);
  }

  const handleLookupPlayers = () => {
    history.push(URLs.lookupPlayers)
  }

  const getLineupButton = () => {
    if (!period) return null;
    const build_or_edit_lineup = period.lineup ? 'edit_your_lineup' : 'build_lineup';
    return <BorderedButton label={t(build_or_edit_lineup)} action={handleBuildLineup}/>;
  }

  return <Layout>
    <div className="home-carousel">
      <img alt="" src="https://volefs-photos.s3.eu-central-1.amazonaws.com/content/slider/img2.jpeg"/>
    </div>
    <div className="home-buttons">
      {getLineupButton()}
      <BorderedButton label={t('lookup_players')} action={handleLookupPlayers}/>
      <BorderedButton label={t('rules')} action={handleBuildLineup}/>
      <BorderedButton label={t('awards')} action={handleBuildLineup}/>
    </div>
  </Layout>;
}

export default Home;
