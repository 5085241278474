import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import API from "../../services/APIService";
import {ProgressState} from "types";

export interface AuthState {
  checked: boolean;
  isAuth: boolean;
  loginStatus: ProgressState
  checkAuthStatus: ProgressState
}

const initialState: AuthState = {
  checked: false,
  isAuth: false,
  loginStatus: "idle",
  checkAuthStatus: "idle",
};

const reducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginInit(state) {
      state.loginStatus = "loading";
    },
    loginSuccess(state) {
      state.loginStatus = "succeeded";
      state.checked = true;
      state.isAuth = true;
    },
    loginError(state) {
      state.loginStatus = "failed";
      state.checked = true;
      state.isAuth = false;
    },
    checkAuthInit(state) {
      state.checkAuthStatus = "loading";
    },
    checkAuthSuccess(state, action: PayloadAction<boolean>) {
      state.checkAuthStatus = "succeeded";
      state.checked = true;
      state.isAuth = action.payload;
    },
    checkAuthError(state) {
      state.checkAuthStatus = "failed";
      state.checked = true;
      state.isAuth = false;
    }
  },
});

const {
  loginInit,
  loginError,
  loginSuccess,
  checkAuthInit,
  checkAuthSuccess,
} = reducer.actions;

export const loginWithUsernameAction = (username: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(loginInit());
  API.login(username)
    .then(() => dispatch(loginSuccess()))
    .catch(e => {
      console.log(`Failed to login: ${e.message}`);
      dispatch(loginError());
    })
};

export const checkAuthAction = () => async (
  dispatch: AppDispatch
) => {
  dispatch(checkAuthInit());
  API.checkAuth()
    .then(res => {
      dispatch(checkAuthSuccess(true))
    })
    .catch(e => {
      console.log(`Failed to login with username: ${e.message}`);
      dispatch(checkAuthSuccess(false));
    })
};


export default reducer;
