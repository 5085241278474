import React from "react";
import './style.scss';

export interface TwoColTableRow {
  label: string
  value: string
}

interface Props {
  rows: TwoColTableRow[]
}

const TwoColTable = ({rows}: Props) => {
  return <div>
    <div className="two-col-table">
      {
        rows.map((row, k) => {
          return <div key={`stat_${k}`} className="two-col-table-item">
            <div key={`label_${k}`} className="two-col-table-item-label">{row.label}</div>
            <div key={`value_${k}`} className="two-col-table-item-value">{row.value || '-'}</div>
          </div>
        })
      }
    </div>
  </div>
}

export default TwoColTable;
