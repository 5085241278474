import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import APIService from "../../services/APIService";
import {PlayerSummary} from "../../types";
import URLs from "../../config/urls";
import PlayerBox from "../../components/PlayerBox";
import FloatingButton from "../../components/FloatingButton";
import {getLineupAction} from "../../store/reducers/lineup";

const LineupCoachPage = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const lineup = useAppSelector(state => state.lineup.lineup)!;
  const [coaches, setCoaches] = useState<PlayerSummary[]>();
  const alreadySelectedIds = lineup.players.reduce((acc: number[], p) => {
    p.player && p.player.id && acc.push(p.player.id);
    return acc;
  }, []);
  const dispatch = useAppDispatch();

  useEffect(() => {
    APIService.searchPlayers({position: "COACH", excludeIds: alreadySelectedIds.join(','), limit: 100})
      .then(res => setCoaches(res.players))
  }, [])

  if (!coaches || !coaches.length) return <Loading />;

  const nextPage = () => history.push(URLs.lineup(lineup.id).type);

  const handleSelect = (id: number) => {
    APIService.setLineupPlayer(lineup.id, id, "PRIMARY", "COACH", 1)
      .then(() => {
        dispatch(getLineupAction(lineup.id));
        nextPage();
      });
  }

  return <div>
    <div className="create-lineup-title" dangerouslySetInnerHTML={{__html: t('lineup_select_coach_title')}}/>
    <div className="player-list">
      {
        coaches.map(c => {
          return <PlayerBox key={c.id} player={c} onClick={handleSelect}/>
        })
      }
    </div>
    <FloatingButton action={nextPage} label={t("choose_formation")} />
  </div>;
}

export default LineupCoachPage;