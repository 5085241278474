import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import APIService from "../../services/APIService";
import {LineupFormation} from "../../types";
import URLs from "../../config/urls";
import FloatingButton from "../../components/FloatingButton";
import FormationBox from "../../components/FormationBox";
import {getLineupAction} from "../../store/reducers/lineup";

const LineupFormationPage = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const lineup = useAppSelector(state => state.lineup.lineup)!;
  const [formations, setFormations] = useState<LineupFormation[]>();

  useEffect(() => {
    APIService.getLineupFormations().then(res => setFormations(res));
  }, [])

  if (!formations || !formations.length) return <Loading />;

  const prevPage = () => history.push(URLs.lineup(lineup.id).coach);
  const nextPage = () => history.push(URLs.lineup(lineup.id).overview.replace(":group", "PRIMARY"));

  const handleSelect = (id: number) => {
    APIService.setLineupFormation(lineup.id, id)
    .then(() => {
      dispatch(getLineupAction(lineup.id));
      nextPage();
    });
  }

  return <div>
    <div className="create-lineup-title">{t('lineup_select_formation_title')}</div>
    <div className="player-list">
      {
        formations.map(f => {
          return <FormationBox key={f.id} formation={f} onClick={handleSelect}/>
        })
      }
    </div>
    <FloatingButton action={prevPage} label={t("back")} direction={"left"} />
    <FloatingButton action={nextPage} label={t("choose_players")} />
  </div>;
}

export default LineupFormationPage;