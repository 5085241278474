import React from "react";
import './style.scss';

interface Props {
  action: () => void;
  label: string
}

const BorderedButton = ({action, label}: Props) => {
  return <div className={`bordered-btn`} onClick={action}>{label}</div>
}

export default BorderedButton;