import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import {PlayerDetails, PlayerSearchQueryParams, PlayerSummary, ProgressState} from "types";
import APIService from "../../services/APIService";

export interface LineupState {
  perPage: number
  page: number
  players: PlayerSummary[]
  playerDetails?: PlayerDetails
  hasMore: boolean
  getPlayersState: ProgressState
  getPlayerDetailsState: ProgressState
}

const initialState: LineupState = {
  players: [],
  perPage: 10,
  page: 1,
  hasMore: false,
  getPlayersState: "idle",
  getPlayerDetailsState: "idle",
};

const reducer = createSlice({
  name: "player",
  initialState,
  reducers: {
    getPlayers(state) {
      state.getPlayersState = "loading";
    },
    getPlayersSuccess(state, action: PayloadAction<{players: PlayerSummary[], total: number, append: boolean}>) {
      state.getPlayersState = "succeeded";
      if (action.payload.append) {
        state.players = [...state.players, ...action.payload.players];
      } else {
        state.players = action.payload.players
      }
      state.hasMore = action.payload.total > state.players.length;
    },
    getPlayersError(state) {
      state.getPlayersState = "failed";
    },
    getPlayerDetails(state) {
      state.getPlayerDetailsState = "loading";
    },
    getPlayerDetailsSuccess(state, action: PayloadAction<PlayerDetails>) {
      state.getPlayerDetailsState = "succeeded";
      state.playerDetails = action.payload;
    },
    getPlayerDetailsError(state) {
      state.getPlayerDetailsState = "failed";
    }
  },
});

const {
  getPlayers,
  getPlayersError,
  getPlayersSuccess,
  getPlayerDetails,
  getPlayerDetailsError,
  getPlayerDetailsSuccess,
} = reducer.actions;

export const searchPlayersAction = (query: PlayerSearchQueryParams) => async (
  dispatch: AppDispatch
) => {
  dispatch(getPlayers());
  APIService.searchPlayers({...query})
    .then(res => {
      dispatch(getPlayersSuccess({players: res.players, total: res.total, append: !!query.append}))
    })
    .catch(() => dispatch(getPlayersError()))

}

export const getPlayerDetailsAction = (id: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(getPlayerDetails());
  APIService.getPlayerDetails(id)
    .then(res => {
      dispatch(getPlayerDetailsSuccess(res))
    })
    .catch(() => dispatch(getPlayerDetailsError()))

}

export default reducer;
