import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Layout from "../components/Layout";
import {getPlayerDetailsAction} from "../store/reducers/player";
import {useAppDispatch, useAppSelector} from "../store";
import Loading from "../components/Loading";
import {PlayerDetailsStats} from "../types";
import Tabs from "../components/Tabs";
import PlayerWeeklyScores from "../components/PlayerWeeklyScores";
import TwoColTable from "../components/TwoColTable";

const LookupPlayerDetails = () => {
  const {t} = useTranslation();
  const {id} = useParams<{id: string}>();
  const idInt = parseInt(id);
  const dispatch = useAppDispatch();
  const playerDetails = useAppSelector(state => state.player.playerDetails);
  const getPlayerDetailsState = useAppSelector(state => state.player.getPlayerDetailsState);

  useEffect(() => {
    if (idInt) {
      dispatch(getPlayerDetailsAction(idInt));
    }
  }, [idInt]);

  if (getPlayerDetailsState !== "succeeded" || !playerDetails) return <Loading />;

  const statsRows = (Object.keys(playerDetails.stats) as Array<keyof PlayerDetailsStats>).map(s => ({label: t('player_stats_' + s), value: `${playerDetails.stats[s]}`}));

  return <Layout>
    <div className="player-details-top">
      <div className="player-details-image">
        <img src={playerDetails.photoUrl} alt="" />
      </div>
      <div className="player-details-name">
        <span>{playerDetails.name}</span>
        <span>{t('position_name_' + playerDetails.positionName)} - {playerDetails.teamName}</span>
      </div>
    </div>
    <Tabs>
      <Tabs.Tab id="stats" key="stats" title={t('statistics')}>
        <TwoColTable rows={statsRows} />
      </Tabs.Tab>
      <Tabs.Tab id="scores" key="scores" title={t('weekly_scores')}>
        <PlayerWeeklyScores />
      </Tabs.Tab>
    </Tabs>
  </Layout>
}

export default LookupPlayerDetails;
