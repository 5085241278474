import AwesomeDebouncePromise from "awesome-debounce-promise";
import useConstant from "use-constant";
import {useAsync} from "react-async-hook";
import {useEffect} from "react";

const useDebounce = (input: string, callback: (inp?: string) => void) => {

  const debouncedCallback = useConstant(() =>
    AwesomeDebouncePromise(callback, 500, {onlyResolvesLast: true})
  );

  useAsync(async () => {
    return debouncedCallback(input)
  }, [debouncedCallback, input]);

  useEffect(() => {
    debouncedCallback();
  }, [debouncedCallback]);

}

export default useDebounce;