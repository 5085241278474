import React, {useEffect, useState} from "react";
import {ReactComponent as Logo} from 'assets/logo.svg';
import {useTranslation} from "react-i18next";
import './style.scss';
import URLs from "../../config/urls";
import {useHistory} from "react-router-dom";
import {clearError} from "../../store/reducers/error";
import {useAppDispatch, useAppSelector} from "../../store";

interface Props {
  children: React.ReactNode
}

const Layout = ({children}: Props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [displayedError, setDisplayedError] = useState<string>('');
  const error = useAppSelector(s => s.error);

  useEffect(() => {
    if (error.message) {
      setDisplayedError(t(error.message));
      dispatch(clearError())
      setTimeout(() => setDisplayedError(''), 2000);
    }
  }, [error.message])

  return <div className="layout-container">
    <div className={`error-box-container${displayedError ? ' error-box-container-active' : ''}`}>
      <div className="error-box">
        {displayedError}
      </div>
    </div>
    <div className="layout-header" onClick={() => history.push(URLs.home)}>
      <Logo className="layout-header-logo" />
      {t('site_header')}
    </div>
    <div className="layout-content">
      {children}
    </div>
  </div>
}

export default Layout;
