import React, {useEffect, useRef} from "react";
import Spinner from "./Spinner";

interface Props {
  enabled: boolean
  onLoadMore: () => void
}

const LoadMore = ({enabled, onLoadMore}: Props) => {
  const ref = useRef(null);

  const scrollObserver = (node: any) => {
    new IntersectionObserver(entries => {
      entries.forEach(en => {
        if (en.intersectionRatio > 0) {
          onLoadMore();
        }
      });
    }).observe(node);
  }

  useEffect(() => {
    if (ref.current) {
      scrollObserver(ref.current);
    }
  }, [scrollObserver, ref]);

  return <div>
    {enabled && <div style={{display: 'flex', margin: '2em', justifyContent: 'center', alignItems: 'center'}} ref={ref}><Spinner/></div>}
  </div>
}

export default LoadMore;