import React from "react";
import "./style.scss";
import {useHistory} from "react-router-dom";

interface ActionProps {
  label: string
  url: string
}

interface Props {
  message: string
  onClose?: () => void
  action?: ActionProps
}

const Popup = ({message, action, onClose}: Props) => {
  const history = useHistory();

  const handleClose = () => {
    if (onClose) onClose();
  }

  return (
    <div className="popup-container" onClick={handleClose}>
      <div className="popup">
        <div className="popup-body">{message}</div>
        {
          action &&
          <div className="popup-buttons">
            <div className="popup-btn--accept" onClick={() => history.push(action.url)}>
              {action.label}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Popup;
