import React from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {Lineup, LineupGroup, LineupPlayer, PlayerPosition, PlayerSummary} from "../../types";
import URLs from "../../config/urls";
import FloatingButton from "../../components/FloatingButton";
import {setError} from "../../store/reducers/error";

interface Params {
  group: LineupGroup
}

const LineupOverviewPage = () => {
  const {t} = useTranslation();
  const {group} = useParams<Params>();
  const history = useHistory();
  const lineup = useAppSelector(state => state.lineup.lineup)!;
  const dispatch = useAppDispatch();

  const renderRows = (position: PlayerPosition) => {

    const renderBox = (p: LineupPlayer) => {
      const url = URLs.lineup(lineup.id).selectPlayer
        .replace(":group", group)
        .replace(":position", position)
        .replace(":rank", p.rank + '');

      if (p.player) {
        return <div key={`${p.position}-${p.rank}`} onClick={() => history.push(url)}
                    className={`formation-overview-image`}>
          <img src={p.player.photoUrl} alt=""/>
          <div className="formation-overview-name">{p.player.name}</div>
        </div>
      }

      return <div key={`${p.position}-${p.rank}`} onClick={() => history.push(url)}
                  className={`formation-overview-box formation-overview-box--${p.position}`}>
        {p.position[0]}{p.rank}
      </div>
    };

    const counts = lineup.formation.split("-").map(i => parseInt(i))
    const calculateRowCounts = () => {
      if (position === "KEEPER") return [1];
      if (position === "DEFENDER") return [counts[0]];
      if (position === "FORWARD") return [counts[counts.length - 1]];
      if (position === "MIDFIELDER") return counts.slice(1, counts.length - 1);
      return []
    }
    const players = lineup.players
      .filter(p => p.group === group)
      .filter(p => p.position === position);

    const rowCounts = calculateRowCounts();
    return rowCounts.map((rc, i) => {
      const skip = rowCounts.slice(0, i).reduce((acc, c) => acc + c, 0);
      return <div className="formation-overview-row" key={i}>
        {players.slice(skip, skip + rc).map(renderBox)}
      </div>
    })
  }

  const prevURL = group === "PRIMARY" ?
    URLs.lineup(lineup.id).type :
    URLs.lineup(lineup.id).overview.replace(":group", "PRIMARY");

  const onNext = () => {
    if (lineup.remainingBudget < 0) {
      dispatch(setError(t('activate_error_over_budget')))
      return;
    }

    const url = group === "PRIMARY" ?
      URLs.lineup(lineup.id).overview.replace(":group", "SUBSTITUTE") :
      URLs.lineup(lineup.id).summary;

    history.push(url);
  }

  const title_key = group === "PRIMARY" ? 'lineup_team_overview_title' : 'lineup_team_substitutes_title'

  const positionList: PlayerPosition[] = ['KEEPER', 'DEFENDER', 'MIDFIELDER', 'FORWARD'];

  return <div>
    <div className="create-lineup-title">{t(title_key, {formation: lineup.formation})}</div>
    <div className="formation-overview-row-container">
      {positionList.map(pos => renderRows(pos))}
    </div>
    <FloatingButton action={() => history.push(prevURL)} direction="left" label={t('back')}/>
    <FloatingButton action={onNext} direction="right" label={t('next')}/>
  </div>;
}

export default LineupOverviewPage;
