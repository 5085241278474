import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {LineupGroup, PlayerSearchQueryParams, PlayerPosition} from "types";
import URLs from "../../config/urls";
import PlayerBox from "../../components/PlayerBox";
import {setPlayerAction} from "../../store/reducers/lineup";
import useDebounce from "../../hooks/useDebounce";
import FloatingButton from "../../components/FloatingButton";
import {searchPlayersAction} from "../../store/reducers/player";
import InputBox from "../../components/InputBox";
import LoadMore from "../../components/LoadMore";

interface Params {
  group: LineupGroup
  position: PlayerPosition
  rank: string
}

const PLAYERS_PER_PAGE = 10;

const LineupSelectPlayerPage = () => {
  const {t} = useTranslation();
  const {group, position, rank} = useParams<Params>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const lineup = useAppSelector(state => state.lineup.lineup)!;
  const players = useAppSelector(state => state.player.players)!;
  const hasMore = useAppSelector(state => state.player.hasMore)!;
  const getPlayersState = useAppSelector(state => state.player.getPlayersState);
  const setPlayerState = useAppSelector(state => state.lineup.setPlayerState)!;

  const alreadySelectedIds = lineup.players.reduce((acc: number[], p) => {
    p.player && p.player.id && acc.push(p.player.id);
    return acc;
  }, []);

  const [query, setQuery] = useState<PlayerSearchQueryParams>({
    position: position,
    limit: PLAYERS_PER_PAGE,
    excludeIds: alreadySelectedIds.join(','),
    append: false,
    skip: 0
  });

  const handleLoadMore = () => setQuery({...query, append: true, skip: players.length});

  useEffect(() => {
    if (setPlayerState === "succeeded") {
      history.push(URLs.lineup(lineup.id).overview.replace(":group", group));
    }
  }, [setPlayerState, history, lineup.id]);

  const search = (name?: string) => {
    if (name !== undefined) setQuery({...query, append: false, name});
  }
  useDebounce(searchTerm, search);

  useEffect(() => {
    dispatch(searchPlayersAction(query));
  }, [query])

  const handleSelect = (id: number) => {
    dispatch(setPlayerAction(lineup.id, id, group, position, parseInt(rank)));
  }

  const playerList = () => {
    if (players) {
      return <div className="player-list">
        {
          players.map(p => {
            return <PlayerBox key={p.id} player={p} onClick={handleSelect}/>
          })
        }
      </div>
    }
    return null;
  }

  const handleContinue = () => {
    if (lineup.remainingBudget < 0) {
      // setError({
      //   message: t('activate_error_over_budget'),
      //   label: t('select'),
      //   url: primaryTeamUrl
      // })
    }
  }

  const prevURL = URLs.lineup(lineup.id).overview.replace(":group", group);

  return <div>
    <div className="create-lineup-title">
      {t('lineup_select_player_title', {position: t(`position_name_${position}`)})}
    </div>
    <InputBox placeholder={t('search_player_placeholder')} onChange={setSearchTerm}/>
    {playerList()}
    <LoadMore enabled={getPlayersState === "succeeded" && hasMore} onLoadMore={handleLoadMore}/>
    <FloatingButton action={() => history.push(prevURL)} direction="left" label={t('back')}/>
  </div>;
}

export default LineupSelectPlayerPage;
