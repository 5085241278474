import React from "react";
import {LineupFormation} from "../../types";
import './style.scss';

interface Props {
  formation: LineupFormation
  onClick: (id: number) => void;
}

const FormationBox = ({formation, onClick}: Props) => {
  return <div className="formation-box">
    <img alt="" src={formation.photoUrl} onClick={() => onClick(formation.id)}/>
  </div>
}

export default FormationBox;