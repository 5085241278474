import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store";
import {createLineupAction, getPeriodAction} from "../store/reducers/lineup";
import {Redirect} from "react-router-dom";
import URLs from "../config/urls";
import Loading from "../components/Loading";

const LineupCreatePage = () => {
  const period = useAppSelector(state => state.lineup.period);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (period && !period.lineup) {
      dispatch(createLineupAction(period.id));
    }
    if (!period) {
      dispatch(getPeriodAction());
    }
  }, [dispatch, period]);

  if (!period || !period.lineup) {
    return <Loading />;
  }

  return <Redirect to={URLs.lineup(period.lineup.id).home} />;
}

export default LineupCreatePage;
