import React from "react";
import './style.scss';

interface Props {
  placeholder: string
  onChange: (value: string) => void
}

const InputBox = ({placeholder, onChange}: Props) => {
  return <div className="input-box-container">
    <div className="input-box-wrapper">
      <input type="text" autoCapitalize={"off"} onChange={e => onChange(e.target.value)} placeholder={placeholder}/>
    </div>
  </div>
}

export default InputBox;