import React, {useEffect, useState} from "react";
import Spinner from "../Spinner";
import {useParams} from "react-router-dom";
import APIService from "../../services/APIService";
import {PlayerWeekScore} from "../../types";
import TwoColTable, {TwoColTableRow} from "../TwoColTable";

const PlayerWeeklyScores = () => {
  const [stats, setStats] = useState<PlayerWeekScore[]>();
  const {id} = useParams<{id: string}>();

  useEffect(() => {
    APIService.getPlayerWeeklyScores(parseInt(id)).then(setStats)
  }, [])

  if (!stats) return <div style={{paddingTop: "3em", textAlign: "center"}}><Spinner/></div>

  const rows: TwoColTableRow[] = stats.map(s => ({label: s.period, value: s.score ? `${s.score}` : '-'}));

  return <TwoColTable rows={rows} />
}

export default PlayerWeeklyScores;
