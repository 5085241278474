import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "store";
import Loading from "components/Loading";
import {useTranslation} from "react-i18next";
import {Route, Switch, useParams, Redirect, useHistory, useLocation, matchPath} from "react-router-dom";
import LineupOverviewPage from "./Overview";
import LineupSummaryPage from "./Summary";
import LineupCoachPage from "./Coach";
import {getLineupAction} from "../../store/reducers/lineup";
import URLs from "../../config/urls";
import LineupTypePage from "./Formation";
import LineupSelectPlayerPage from "./SelectPlayer";
import ViewHelper from "../../services/ViewHelper";
import './style.scss'
import Layout from "../../components/Layout";

interface ParamTypes {
  id: string
}

const LineupIndexPage = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const {id} = useParams<ParamTypes>();

  const lineup = useAppSelector(state => state.lineup.lineup);

  useEffect(() => {
    dispatch(getLineupAction(parseInt(id)))
  }, [id, dispatch])

  if (!lineup) return <Loading/>;

  const getURL = () => {
    return URLs.lineup(lineup.id).coach;
  }

  const steps = [
    {
      title: t('step_1'),
      urls: [URLs.lineup(lineup.id).coach],
    },
    {
      title: t('step_2'),
      urls: [URLs.lineup(lineup.id).type],
    },
    {
      title: t('step_3'),
      urls: [
        URLs.lineup(lineup.id).overview.replace(":group", "PRIMARY"),
        URLs.lineup(lineup.id).overview.replace(":group", "SUBSTITUTE"),
        URLs.lineup(lineup.id).selectPlayer
      ],
    },
    {
      title: t('step_4'),
      urls: [URLs.lineup(lineup.id).summary],
    },
  ];

  const isCurrentStep = (s: any) => {
    for (let i = 0; i < s.urls.length; i++) {
      const match = matchPath(location.pathname, {
        path: s.urls[i],
        exact: true
      })
      if (match) return true;
    }
    return false;
  }

  return <Layout>
    <div className="lineup-top">
      <div className={"progress-bar-container"}>
        <ul className={"progress-bar"}>
          {
            steps.map((s, i) => {
              const classes = isCurrentStep(s) ? `progress-bar-step--active` : `progress-bar-step`;
              return <li key={i} onClick={() => history.push(s.urls[0])}
                  className={classes}>
                {s.title}
              </li>;
            })
          }
        </ul>
      </div>
      <div className={`budget${lineup.remainingBudget < 0 ? ' budget--overflow' : ''}`}>{t('budget')}: {ViewHelper.formatPrice(lineup.remainingBudget)} TL</div>
    </div>
    <Switch>
      <Route exact path={URLs.lineup(lineup.id).coach}>
        <LineupCoachPage/>
      </Route>
      <Route exact path={URLs.lineup(lineup.id).type}>
        <LineupTypePage/>
      </Route>
      <Route exact path={URLs.lineup(lineup.id).overview}>
        <LineupOverviewPage/>
      </Route>
      <Route exact path={URLs.lineup(lineup.id).selectPlayer}>
        <LineupSelectPlayerPage/>
      </Route>
      <Route exact path={URLs.lineup(lineup.id).summary}>
        <LineupSummaryPage/>
      </Route>
      <Route exact path={URLs.lineup(lineup.id).home}>
        <Redirect to={getURL()}/>
      </Route>
    </Switch>
  </Layout>;
}

export default LineupIndexPage;