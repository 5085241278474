import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../store";
import {loginWithUsernameAction} from "../store/reducers/auth";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";

interface FormData {
  username: string;
}

const Login = () => {
  const auth = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {register, handleSubmit} = useForm();
  const loginStatus = useAppSelector(state => state.auth.loginStatus);

  const onSubmit = (data: FormData) => {
    dispatch(loginWithUsernameAction(data.username));
  };

  useEffect(() => {
    if (auth.isAuth) {
      history.push('/');
    }
  })

  const btn = {
    padding: ".5em 2em",
    borderRadius: ".2em",
    color: "white",
    backgroundColor: "#ec2227",
    border: "0",
  }

  return <Layout>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "80vh"}}>
        <input
          autoComplete={"off"}
          autoCapitalize={"none"}
          ref={register}
          type="text"
          name="username"
          placeholder="Vole kullanıcı adı"
          required={true}
          style={{width: 200, marginBottom: "5vh"}}
        />
        {
          loginStatus === "loading" ? <Spinner /> : <button type="submit" style={btn}>Giriş</button>
        }
      </div>
    </form>
  </Layout>;
}

export default Login;
