import React from 'react';

const ErrorFallback = ({error, resetErrorBoundary}: any) => {
  return <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
}

export default ErrorFallback;