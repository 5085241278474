import React, {useState} from "react";
import './style.scss';

interface Props {
  onSelect?: (id: string) => void
  children: JSX.Element[]
}

interface TabProps {
  id: string
  title: string
  children: React.ReactNode
}

const Tab = ({children}: TabProps) => {
  return <div className="tabs-tab-content">
      {children}
  </div>
}

const Tabs = ({onSelect, children}: Props) => {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (idx: number) => {
    setSelected(idx)
    if (onSelect) {
      React.Children.forEach(children, (child, i) => {
        if (i === idx) {
          onSelect(child.props.id)
        }
      });
    }
  }

  return <div className="tabs-container">
    <div className="tabs-title-row">
      {
        React.Children.map(children, (child, i) => {
          const classes = ["tabs-tab-title", `tabs-tab-title${i}of${children.length}`];
          if (i === selected) {
            classes.push("tabs-tab-title--active");
          }
          return <div onClick={() => handleSelect(i)} className={classes.join(" ")}>{child.props.title}</div>;
        })
      }
      <div className="tabs-tab-title-slider"/>
    </div>
    <div className="tabs-content">
      {
        React.Children.map(children, (child, i) => {
          if (i === selected) {
            return child;
          }
          return null;
        })
      }
    </div>
  </div>
}

Tabs.Tab = Tab;

export default Tabs;
