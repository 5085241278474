import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store";
import {getPeriodAction} from "../store/reducers/lineup";
import Loading from "../components/Loading";
import Error from "../components/Error";
import {useTranslation} from "react-i18next";
import {useHistory, Link} from "react-router-dom";
import URLs from "../config/urls";
import APIService from "../services/APIService";
import Layout from "../components/Layout";

const GameHome = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const getPeriodState = useAppSelector(state => state.lineup.getPeriodState);
  const createLineupState = useAppSelector(state => state.lineup.createLineupState);
  const loading = createLineupState === "loading" || getPeriodState === "loading";
  const period = useAppSelector(state => state.lineup.period);

  useEffect(() => {
    dispatch(getPeriodAction());
  }, [dispatch])

  if (getPeriodState === "failed") {
    return <Error message={t('error')}/>;
  }

  if (loading) return <Loading/>;

  if (!period) return <Error message={t('no_active_period')}/>;

  const handleEditLineup = (id: number) => {
    APIService.deactivateLineup(id)
      .then(() => {
        history.push(URLs.lineup(id).overview.replace(":group", "PRIMARY"));
      })
  }

  return <Layout>
    <div className="home-period-title">{period.name}</div>
    {
      period.lineup && period.lineup.status === "PASSIVE" &&
      <div className="home-lineup-description">
        <div>{t('your_have_passive_lineup')}</div>
        <button onClick={() => handleEditLineup(period.lineup!.id)}>{t('continue')}</button>
      </div>
    }
    {
      period.lineup && period.lineup.status === "ACTIVE" &&
      <div className="home-lineup-description">
        <div>{t('your_have_active_lineup')}</div>
        <button onClick={() => handleEditLineup(period.lineup!.id)}>{t('see_lineup')}</button>
      </div>
    }
    {
      !period.lineup &&
      <div className="home-lineup-description">
        <Link to={URLs.createLineup}><button>{t('join_game')}</button></Link>
      </div>
    }
  </Layout>;
}

export default GameHome;