import React from "react";
import {PlayerSummary} from "../../types";
import './style.scss';
import ViewHelper from "../../services/ViewHelper";

interface Props {
  player: PlayerSummary
  onClick: (id: number) => void;
}

const PlayerBox = ({player, onClick}: Props) => {
  return <div className="player-box">
    <img alt={player.name} src={player.photoUrl} onClick={() => onClick(player.id)}/>
    <div className="player-box-name">{player.name}</div>
    <div className="player-box-value">{ViewHelper.formatPrice(player.value)} TL</div>
  </div>
}

export default PlayerBox;