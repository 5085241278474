import React from "react";

interface Props {
  message: string
}

const Error = ({message}: Props) => {
  return <div style={{border: "2px solid #ffb4b4", backgroundColor: "#ffffff", color: "#b80000", margin: "3rem", padding: "1rem", textAlign: "center"}}>
    {message}
  </div>
}

export default Error;