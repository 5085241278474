import React from 'react';
import ErrorFallback from './pages/ErrorFallback';
import {ErrorBoundary} from 'react-error-boundary'
import Routes from "./Routes";
import './App.scss'
import {Provider} from "react-redux";
import store from "./store";
import {BrowserRouter as Router} from "react-router-dom";

const App = () => {

  return (
    <div className="app">
      <Provider store={store}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >

          <Router>
            <Routes/>
          </Router>
        </ErrorBoundary>
      </Provider>
    </div>
  );
};

export default App;
